.homeInfra{
    display: flex;
    align-items: center;
    cursor: pointer;
    font-weight: bold;
    padding: 20px;
    padding-left: 5%;

  }


 