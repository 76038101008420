/* This is layout for dashboard*/
.dashboard1{
    overflow: hidden;
}

.dashboard{
    display: flex;
    padding: 40px;
    margin: 0px;
    text-align: center;
    
}



