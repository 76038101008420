/* This container wraps the three elements that links to the social networks */
.footer{
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 30px;
    padding-bottom: 20px;
    flex-wrap: wrap;
    min-width: 400px;
}

/* The image used for discord */
.footer__discord > img{
    height: 50px;
}

/* The icon used for redit */
.footer__reddit > .MuiSvgIcon-root{
    font-size: 50px;
    color: 	#FF5700; 
    margin-left: 30px;
}

/* The icon used for telegram */
.footer__telegram > .MuiSvgIcon-root{
    font-size: 50px;
    color: #0088cc; 
    margin-left: 30px;
}

/* when hovered over the icons cursor changes to pointer */
.cursor{
    cursor: pointer;
    margin: 20px;
   
}
