/* This is layout for info*/
.info{
    display: flex;
    padding: 0px;
    margin-top: 0px;
    margin-left: 80px;
    text-align: center;
    }


.collapsible-header{
    display: flex;
    flex-direction: column;
    font-family: Vampetica, Arial, Helvetica, sans-serif;
    margin-top: 0px;
    margin-left: 0px;
    size: larger;
    color: maroon;
 
}
.listItemContent{
    margin-left: 20px;
    margin-bottom: 10px;
    font-family: Arial, Helvetica, sans-serif;
    color: grey;
}

/* CSS for image in list items */
.listItemContentImageXSmall{
    width: 20px;
}
.listItemContentImageSmall{
    width: 100px;
}
.listItemContentImageMedium{
    width: 200px;
}
.listItemContentImageLarge{
    width: 400px;
}
.collapsible {
    cursor: pointer;
    padding: 10px;
    text-align: left;
    font-size: 17px;
    font-weight: 600;
  }
  
  .content {
    padding: 0 18px;
    display: none;
    text-align: center;
  }

  .textLeft {
    padding: 0 18px;
    display: none;
    margin-top: ;
    text-align: left;
  }

  .aboutUsText {
   /* padding: 0 18px;*/
  
    margin-top: 7px;
    margin-left: 10px;
    margin-right: 40px;
    text-align: left;
    font-family: Arial, Helvetica, sans-serif;
    color: grey; 
}
.aboutUsHeader {
    /* padding: 0 18px;*/
   
     margin-top: 5px;
     margin-left: 0px;
     margin-right: 40px;
     text-align: left;
     font-family: Vampetica, Arial, Helvetica, sans-serif;
     color: maroon; 
     font-size: 17px;
     font-weight: 600;
}



@media only screen and (max-width: 600px) {
  
    .info{
        display: flex;
        padding: 0px;
        margin-top: 0px;
        margin-left: 30px;
        text-align: center;
        }
  }

  @media only screen and (max-width: 400px) {
  
    .info{
        display: flex;
        padding: 0px;
        margin-top: 0px;
        margin-left: 30px;
        text-align: center;
        }
    
  
  }