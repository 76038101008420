/* This is the header wrapper for dashboard, through which you can navigate to different dashboards*/
.dashboardHeader{
    display: flex;
    padding: 10px;
    padding-left: 1%;
    margin: 0px;
    margin-left: 5%;
    font-family: Vampetica, Arial, Helvetica, sans-serif;
    font-size: larger;
}

/* This is the header wrapper for dashboard, through which you can navigate to different dashboards*/
.infoHeader{
    display: flex;
    padding: 10px;
    padding-left: 1%;
    margin: 0px;
    margin-left: 5%;
    font-family: Vampetica, Arial, Helvetica, sans-serif;
    font-size: larger;
}


/* controls how far down the page the sub menus appear */
/* this targets the element/name used for each dashboard */
.dashboardHeader>div{
    margin: 12px;
    cursor: pointer;
    padding-top: 30px;

}

/* controls how far down the page the sub menus appear */
/* this targets the element/name used for each dashboard */
.infoHeader>div{
    margin: 12px;
    cursor: pointer;
    padding-top: 30px;

}

@media only screen and (max-width: 600px) {
  
    .dashboardHeader{
        font-size: medium;
    }
    
    .infoHeader{
        font-size: medium;
    }
    
  }