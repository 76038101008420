

/* Container that Wraps all the text blocks on home page */
.textBlock__wrapper{
    display: flex;
    width: 100vw;
    justify-content: center;
    flex-wrap: wrap;
    padding: 0px;
    /* align-items: center; */
 
  }

  /* The text block itself on the home page */
.textBlock {
  display: flex;
   /* Change the width from here */
  /*max-width: 550px; */
  max-width: 30vw;
  min-width: 300px;
  background-color: #f8f9fa;
  border-radius: 20px;
  margin: 5px;
  min-height: 200px;
  flex-direction: column;
  align-items: center;
}


/* The icon use in the text block */
.textBlock__icon {
  margin: 20px;
  margin-right: 5px;
  border: 1px solid lightgrey;
  padding: 10px;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  max-height: fit-content;
  max-width: fit-content;
  color: maroon;
}

/* The text wrapper in text block */
.textBlock__right {
  margin: 20px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
}

/* The heading of text block */
.textBlock__heading {
  display: flex;
  flex-wrap: wrap;
  font-weight: 700;
  /*color: #374151;*/
  color: maroon;
  font-size: 18px;
  font-family: Arial, Helvetica, sans-serif;
  align-items: center;
}

/* The description of text block */
.textBlock__desc {
  margin-top: 5px;
  margin-bottom: 5px;
  color: #9ca3af;
  font-size: 16px;
  font-weight: 500;
  line-height: 2;
  /* Change the width from here */
  /* max-width: 350px;    */
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  font-family: Arial, Helvetica, sans-serif;
  text-align: justify;
  }

  /* Controls the icons size on the textblocks of home page */
  .textBlock__icon > .MuiSvgIcon-root {
    font-size: 35px;
  }
  


@media only screen and (max-width: 800px) {
  

  .textBlock {
    flex-direction: column;
    width: 98vw;
  }

}